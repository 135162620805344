import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import styles from "./styles.module.less";

const ucLoader =
    typeof window !== "undefined" ? require("../../lib/ucLoader") : null;

const CommunityCareHome = ({ data }) => {
    let sites = data.allCommunityCarePage.nodes.map(node => {
        return {
            ref: node.slug,
            companyName: node.title,
            companyLogo: node.companyLogo,
            loanOfficerName: node.team
                ? node.team.profile.name
                : node.loanOfficer
                ? node.loanOfficer.profile.name
                : "",
        };
    });

    return (
        <>
            <Helmet>
                <script dangerouslySetInnerHTML={{ __html: ucLoader }} />
            </Helmet>
            <div className={styles.communityCare}>
                <img
                    className={styles.commImage}
                    src="https://baycdn.blob.core.windows.net/homeloans/site-assets/community-care/community-care-logo.png"
                    alt="Site logo"
                />
                <div className={styles.siteBlock}>
                    {sites.map((s, i) => {
                        return (
                            <div key={i} className={styles.hr}>
                                <a href={s.ref}>
                                    <div className={styles.companyLogo}>
                                        <img
                                            alt="Company Logo"
                                            className={styles.companyImage}
                                            data-blink-src={s.companyLogo}
                                        />
                                    </div>
                                    <div>
                                        {`${s.companyName} - ${s.loanOfficerName}`}
                                    </div>{" "}
                                </a>
                            </div>
                        );
                    })}
                </div>{" "}
            </div>
        </>
    );
};

export default CommunityCareHome;

export const pageQuery = graphql`
    query getCommunitySites {
        allCommunityCarePage(
            filter: { hidden: { eq: false } }
            sort: { fields: slug, order: ASC }
        ) {
            nodes {
                slug
                title
                companyLogo
                loanOfficer {
                    profile {
                        name
                    }
                }
                team {
                    profile {
                        name
                    }
                }
            }
        }
    }
`;
